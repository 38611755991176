/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var MAIN = {};

  MAIN.setMenu = function()
  {
    var mql = window.matchMedia('screen and (max-width: 1280px)');

    function toggleMenu() {
      $('.bar').toggleClass('animate');
      $('body').toggleClass('is-open');
    }

    function collapseMenu() {
      if ($('body').hasClass('is-open')) {
        $('.bar').toggleClass('animate');
        $('body').toggleClass('is-open');
      }
    }

    function checkBreakPoint(mql) {
      if (mql.matches) {
        $('.nav-menu, .list-nav a').on('click', toggleMenu);
      } else {
        $('.nav-menu, .list-nav a').off('click', toggleMenu);
      }
    }

    mql.addListener(checkBreakPoint);
    checkBreakPoint(mql);
  };

  MAIN.setIas = function()
  {
    var ias = $.ias({
      container:      '.ias-container',
      item:           '.ias-item',
      pagination:     '.posts-navigation',
      next:           '.nav-previous > a',
      negativeMargin: 600
    });

    ias.extension(new IASSpinnerExtension({
      src: '/assets/images/loader.svg'
    }));

    ias.extension(new IASTriggerExtension({
      text: 'LOAD MORE'
    }));
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var UNIDESIGN = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        MAIN.setMenu();
        MAIN.setIas();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = UNIDESIGN;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
